<template>
    <div>
        <div
            v-if="loadingBmAccess"
            class="py-5">
            <loader />
        </div>
        <div v-else>
            <v-alert
                :value="!paymentMethodValid && addAccountDialogError"
                class="mb-5">
                You need to complete the ad account payment process
                to fulfill this step.  Please click "Add Payment Method"
                below or add payment details directly into ads manager
            </v-alert>

            <v-alert
                :value="errors.length"
                class="mb-5">
                <p>
                    There were errors while processing. Please try again or contact support if
                    the problems continue.
                </p>
                <ul>
                    <li
                        v-for="error in errors"
                        :key="error">
                        {{ error }}
                    </li>
                </ul>
            </v-alert>


            <styled-callout
                v-if="requiresLeadsTos && !pageAccessValid"
                class="layout align-center mb-3">
                <div class="flex shrink mr-3">
                    <status-icon
                        value="warning"
                        size="25" />
                </div>
                <div class="flex">
                    <p>
                        {{ currentDealer.name }} needs to accept page access to
                        {{ currentDealer.agency.name }} before leads TOS can be accepted.
                        <a
                            href="#"
                            @click.prevent="$store.dispatch('goToStepByFeature', 'facebook_page')">
                            Click here for more information and instructions on obtaining page access</a>.
                    </p>
                </div>
            </styled-callout>


            <template v-if="!overrideBmAccess">
                <styled-callout
                    v-if="bmAccessStatus == 'user_already_in_bm'"
                    class="layout align-center mb-3">
                    <div class="flex shrink mr-3">
                        <status-icon size="25" />
                    </div>
                    <div class="flex">
                        <h2>You Have Business Manager Access!</h2>
                        <p>
                            We've detected your BuyerBridge email address ({{ user.email }})
                            in {{ currentDealer.agency.name }}'s
                            business manager so can now complete the steps below!
                        </p>
                    </div>
                </styled-callout>

                <styled-callout
                    v-if="bmAccessStatus == 'user_not_in_bm' && !overrideBmAccess"
                    class="layout align-center mb-3">
                    <div class="flex shrink mr-3">
                        <status-icon
                            value="error"
                            size="25" />
                    </div>
                    <div class="flex mr-5">
                        <h2>You Need Business Manager Access</h2>
                        <p>
                            We couldn't find {{ user.email }} in {{ currentDealer.agency.name }}'s
                            business manager. You will need access to complete this step or will need
                            to coordinate with someone who does.
                        </p>
                        <p>
                            If you know you have business manager access you can
                            <a
                                href="#"
                                @click.prevent="overrideBmAccess = true">
                                ignore this message
                            </a>
                            or <router-link to="user-settings">
                                update your BuyerBridge email
                            </router-link>
                            to match what's in the Facebook Business Manager
                        </p>
                    </div>
                </styled-callout>
            </template>

            <styled-slideout v-model="paymentMethodSlideoutActive">
                <div class="pa-5">
                    <h2>Adding an Ad Account Payment Method</h2>
                    <p>
                        Facebook ads ain't free!  Facebook requires a payment method
                        before any ads can run.
                    </p>
                    <p>
                        You can use the "Add Payment Method"
                        button here to enter credit card details directly into Facebook
                        or you (or someone else) can add payment details to the account
                        directly at the link below:
                    </p>
                    <p>
                        <a
                            :href="paymentSettingsUrl"
                            target="_blank">Payment Methods &amp; Settings ({{ dealerFacebookAdAccount }})</a>
                        <action-button
                            v-clipboard:copy="paymentSettingsUrl"
                            v-clipboard:success="onCopy"
                            icon="copy" />
                    </p>
                    <p>
                        <em>Note that if you're entering payment details manually
                            you'll need to click "recheck status" after completing the
                            process so BuyerBridge can validate that the process is complete</em>
                    </p>
                </div>
            </styled-slideout>

            <styled-slideout v-model="customAudienceTosSlideoutActive">
                <div class="pa-5">
                    <h2>Accepting Custom Audience TOS</h2>
                    <p>
                        Facebook requires that all advertisers using custom
                        audiences accept their terms of service.  Custom audiences
                        allow for the creation of dynamic lists of Facebook users
                        from various sources (website visitors, custom lists, Oracle data, etc)
                    </p>
                    <p>
                        You can click the "Accept Custom Audience TOS" button here then
                        accept Facebook's terms and we will automatically begin rechecking
                        the custom audience TOS status or you can use/share the URL below:
                    </p>
                    <p>
                        <a
                            :href="customAudienceTosUrl"
                            target="_blank">Custom Audience Terms of Service ({{ dealerFacebookAdAccount }})</a>
                        <action-button
                            v-clipboard:copy="customAudienceTosUrl"
                            v-clipboard:success="onCopy"
                            icon="copy" />
                    </p>
                    <p>
                        <em>Note that if you use or share the link above
                            you'll need to click "recheck status" after accepting
                            the terms so BuyerBridge can validate that the process is complete</em>
                    </p>
                </div>
            </styled-slideout>

            <styled-slideout v-model="leadsTosSlideoutActive">
                <div class="pa-5">
                    <h2>Accepting Leads TOS</h2>
                    <p>
                        Facebook requires that all advertisers using lead ads accept their terms
                        of service. Lead ads allow advertisers to collect contact information directly
                        within Facebook advertisements.
                    </p>
                    <p>
                        You can click the "Accept Leads TOS" button here then
                        accept Facebook's terms and we will automatically begin rechecking
                        the leads TOS status or you can use/share the URL below:
                    </p>
                    <p>
                        <a
                            :href="leadsTosUrl"
                            target="_blank">Leads Terms of Service ({{ dealerFacebookPage }})</a>
                        <action-button
                            v-clipboard:copy="leadsTosUrl"
                            v-clipboard:success="onCopy"
                            icon="copy" />
                    </p>
                    <p>
                        <em>Note that if you use or share the link above
                            you'll need to click "recheck status" after accepting the terms
                            so BuyerBridge can validate that the process is complete</em>
                    </p>
                </div>
            </styled-slideout>


            <h2>Ad Account Configuration</h2>
            <p class="mb-4">
                Your ad account is now powered by BuyerBridge 🔥!
                Please take action on the items below to ensure
                it's configured properly for this {{ onboardingType }}:
            </p>

            <div class="panel">
                <div
                    v-if="requiresPaymentMethod"
                    class="layout wrap align-center mb-4">
                    <div class="flex xs12 md6 lg7 layout align-center">
                        <status-icon
                            :value="paymentMethodValid ? 'success' : 'warning'"
                            size="25" />
                        <span class="ml-3">
                            {{ paymentMethodValid ? 'Configured' : 'Configure' }} a payment method for this ad account
                        </span>
                    </div>
                    <div class="flex xs12 md6 lg5 layout align-center">
                        <div class="flex grow">
                            <styled-tooltip
                                :disabled="paymentMethodTooltipDisabled">
                                <styled-button
                                    :disabled="paymentMethodDisabled"
                                    :loading="loadingAdAccountAccess"
                                    gray
                                    outline
                                    full-width
                                    class="text-uppercase"
                                    @click="addPaymentMethod">
                                    Add Payment Method
                                </styled-button>
                                <template #content>
                                    Your Facebook account needs to have access to
                                    {{ currentDealer.agency.name }}'s business manager so we
                                    can automatically add you to {{ currentDealer.name }}'s
                                    ad account add a payment method.
                                </template>
                            </styled-tooltip>
                        </div>
                        <div class="flex shrink ml-2">
                            <action-button
                                icon="question-circle"
                                size="18"
                                @click="paymentMethodSlideoutActive = !paymentMethodSlideoutActive" />
                        </div>
                    </div>
                </div>

                <div
                    v-if="requiresCustomAudienceTos"
                    class="layout wrap align-center mb-4">
                    <div class="flex xs12 md6 lg7 layout align-center">
                        <status-icon
                            :value="customAudienceTosValid ? 'success' : 'warning'"
                            size="25" />
                        <span class="ml-3">
                            {{ customAudienceTosValid ? 'Accepted' : 'Accept' }} Facebook's custom audience TOS
                        </span>
                    </div>
                    <div class="flex xs12 md6 lg5 layout align-center">
                        <div class="flex grow">
                            <styled-tooltip
                                :disabled="customAudienceTosTooltipDisabled">
                                <styled-button
                                    :disabled="customAudienceTosDisabled"
                                    :loading="loadingAdAccountAccess"
                                    gray
                                    outline
                                    full-width
                                    class="text-uppercase"
                                    @click="openCustomAudienceTos">
                                    Accept Custom Audience TOS
                                </styled-button>
                                <template #content>
                                    Your Facebook account needs to have access to
                                    {{ currentDealer.agency.name }}'s business manager so we
                                    so we can automatically add you to {{ currentDealer.name }}'s
                                    ad account in order to allow you to accept the custom audience TOS
                                </template>
                            </styled-tooltip>
                        </div>
                        <div class="flex shrink ml-2">
                            <action-button
                                icon="question-circle"
                                size="18"
                                @click="customAudienceTosSlideoutActive = !customAudienceTosSlideoutActive" />
                        </div>
                    </div>
                </div>

                <div
                    v-if="requiresLeadsTos"
                    class="layout wrap align-center">
                    <div class="flex xs12 md6 lg7 layout align-center">
                        <status-icon
                            :value="leadsTosValid ? 'success' : 'warning'"
                            size="25" />
                        <span class="ml-3">
                            {{ leadsTosValid ? 'Accepted' : 'Accept' }} leads TOS for the page
                        </span>
                    </div>
                    <div class="flex xs12 md6 lg5 layout align-center">
                        <div class="flex">
                            <styled-tooltip
                                :disabled="leadsTosTooltipDisabled">
                                <styled-button
                                    :disabled="leadsTosDisabled"
                                    :loading="loadingPageAccess"
                                    gray
                                    outline
                                    full-width
                                    class="text-uppercase text"
                                    @click="openLeadsTos">
                                    Accept Leads TOS
                                </styled-button>
                                <template
                                    v-if="!pageAccessValid"
                                    #content>
                                    {{ currentDealer.name }} needs to accept page access to
                                    {{ currentDealer.agency.name }} before leads TOS can be accepted.
                                </template>
                                <template
                                    v-else-if="!userInPage"
                                    #content>
                                    Your Facebook account needs to have access to the page
                                    if you are accepting leads TOS.  Please follow the instructions
                                    above to ensure you are in the {{ currentDealer.agency.name }}
                                    business manager so we can automatically add you to
                                    {{ currentDealer.name }}'s page
                                </template>
                            </styled-tooltip>
                        </div>
                        <div class="flex shrink ml-2">
                            <action-button
                                icon="question-circle"
                                size="18"
                                @click="leadsTosSlideoutActive = !leadsTosSlideoutActive" />
                        </div>
                    </div>
                </div>

                <div class="text-xs-right mt-3">
                    <action-button
                        icon="refresh"
                        position="left"
                        size="15"
                        :loading="onboardingStatusLoading"
                        @click="onRecheckOnboardingStatus">
                        {{ onboardingStatusLoading ? 'Rechecking status' : 'Recheck status' }}
                    </action-button>
                </div>
            </div>
        </div>

        <div class="layout align-center">
            <back-button
                @click="$store.dispatch('goToPreviousStep')" />
            <a
                href="#"
                class="ml-auto d-inline-block mr-3"
                @click.prevent="$store.dispatch('handleSkipStepRequest')">
                skip &amp; finish later
            </a>
            <styled-button
                :disabled="!valid"
                @click="$store.dispatch('goToNextStep')">
                CONTINUE
            </styled-button>
        </div>
    </div>
</template>

<script>
import BackButton from '@/components/globals/BackButton';
import StatusIcon from '@/components/globals/StatusIcon';
import Loader from '@/components/globals/Loader';
import StyledSlideout from '@/components/globals/StyledSlideout';
import StyledCallout from '@/components/globals/StyledCallout';
import StyledButton from '@/components/globals/StyledButton';
import ActionButton from '@/components/globals/ActionButton';
import StyledTooltip from '@/components/globals/StyledTooltip';
import { mapGetters, mapState } from 'vuex';
import { UPDATE_ONBOARDING_DATA } from '@/store/mutation-types';
import { get } from 'lodash';

export default {
    name: 'OnboardingAdAccountDependencies',
    components: {
        BackButton,
        Loader,
        StyledButton,
        StatusIcon,
        StyledTooltip,
        StyledCallout,
        ActionButton,
        StyledSlideout
    },
    props: {
        previousStep: {
            type: Number,
            default: null
        },
        nextStep: {
            type: Number,
            default: null
        }
    },
    data() {
        return {
            addAccountDialogError: false,
            bmAccessStatus: null,
            loadingBmAccess: false,
            checkingUserInAdAccount: true,
            userInAdAccount: false,
            userInPage: false,
            adAccountMessage: '',
            loadingPageAccess: false,
            loadingAdAccountAccess: false,
            errors: [],
            onboardingStatusLoading: false,
            customAudienceTosDetectionInterval: null,
            customAudienceTosDetectionActive: false,
            customAudienceTosDetectionTimeout: false,
            customAudienceTosDetectionTries: 0,
            customAudienceTosSlideoutActive: false,
            leadsTosDetectionInterval: null,
            leadsTosDetectionActive: false,
            leadsTosDetectionTimeout: false,
            leadsTosDetectionTries: 0,
            leadsTosSlideoutActive: false,
            paymentMethodDetectionInterval: null,
            paymentMethodDetectionActive: false,
            paymentMethodDetectionTimeout: false,
            paymentMethodDetectionTries: 0,
            paymentMethodSlideoutActive: false
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.user.user,
            currentDealer: (state) => state.dealer.currentDealer,
            onboardingType: (state) => state.onboarding.type,
            onboardingData: (state) => state.onboarding.data
        }),
        ...mapGetters([
            'dealerFacebookAdAccountAnnotation',
            'dealerFacebookAdAccount',
            'dealerFacebookPageAnnotation',
            'dealerFacebookPage',
            'requiredFeatures',
            'incompleteFeatures',
        ]),
        pageAccessValid() {
            return !this.incompleteFeatures.includes('facebook_page');
        },
        requiresPaymentMethod() {
            return this.requiredFeatures.includes('facebook_ad_account');
        },
        paymentMethodValid() {
            return !this.incompleteFeatures.includes('facebook_ad_account');
        },
        paymentMethodDisabled() {
            if (this.paymentMethodValid) {
                return true;
            }

            if (this.overrideBmAccess) {
                return false;
            }

            return !this.userInAdAccount;
        },
        paymentMethodTooltipDisabled() {
            if (this.paymentMethodValid) {
                return true;
            }

            if (this.overrideBmAccess) {
                return true;
            }

            return this.userInAdAccount;
        },
        paymentSettingsUrl() {
            return `https://business.facebook.com/ads/manager/account_settings/account_billing/?act=${this.dealerFacebookAdAccount}&business_id=${this.currentDealer.agency.facebook_business_id}&page=account_settings&tab=account_billing_settings`;
        },
        requiresCustomAudienceTos() {
            return this.requiredFeatures.includes('facebook_custom_audience_tos');
        },
        customAudienceTosValid() {
            return !this.incompleteFeatures.includes('facebook_custom_audience_tos');
        },
        customAudienceTosDisabled() {
            if (this.customAudienceTosValid) {
                return true;
            }

            if (this.overrideBmAccess) {
                return false;
            }

            return !this.userInAdAccount;
        },
        customAudienceTosTooltipDisabled() {
            if (this.customAudienceTosValid) {
                return true;
            }

            if (this.overrideBmAccess) {
                return true;
            }

            return this.userInAdAccount;
        },
        requiresLeadsTos() {
            return this.requiredFeatures.includes('facebook_lead_tos');
        },
        leadsTosValid() {
            return !this.incompleteFeatures.includes('facebook_lead_tos');
        },
        leadsTosDisabled() {
            if (this.leadsTosValid) {
                return true;
            }

            if (this.overrideBmAccess) {
                return false;
            }

            return (!this.userInPage || !this.pageAccessValid);
        },
        leadsTosTooltipDisabled() {
            if (this.leadsTosValid) {
                return true;
            }

            if (this.overrideBmAccess) {
                return true;
            }

            return this.userInPage && this.pageAccessValid;
        },
        outstandingFeatures() {
            const applicableFeatures = [
                'facebook_ad_account',
                'facebook_custom_audience_tos',
                'facebook_lead_tos'
            ];

            return applicableFeatures.filter(feature => this.incompleteFeatures.includes(feature));
        },
        customAudienceTosUrl() {
            return `https://business.facebook.com/customaudiences/app/tos/?act=${this.dealerFacebookAdAccount}&business_id=${this.currentDealer.agency.facebook_business_id}`;
        },
        leadsTosUrl() {
            return `https://facebook.com/ads/leadgen/tos?page_id=${this.dealerFacebookPage}&business_id=${this.currentDealer.agency.facebook_business_id}`;
        },
        valid() {
            return (
                (this.requiresPaymentMethod ? this.paymentMethodValid : true) &&
                (this.requiresCustomAudienceTos ? this.customAudienceTosValid : true) &&
                (this.requiresLeadsTos ? this.leadsTosValid : true)
            );
        },
        overrideBmAccess: {
            get() {
                return this.onboardingData.overrideBmAccess || false;
            },
            set(isOverridden) {
                this.$store.commit(UPDATE_ONBOARDING_DATA, {
                    overrideBmAccess: isOverridden
                });
            }
        }
    },
    watch: {
        bmAccessStatus(status) {
            if (status === 'user_already_in_bm') {
                if (this.requiresLeadsTos) {
                    this.grantPageAccess();
                }
                if (this.requiresPaymentMethod || this.requiresCustomAudienceTos) {
                    this.grantAdAccountAccess();
                }
            }
        },
        leadsTosValid(isValid) {
            // If it's validated and we're checking it disable
            if (isValid && this.leadsTosDetectionActive) {
                this.leadsTosDetectionActive = false;
                clearInterval(this.leadsTosDetectionInterval);
            }
        },
        customAudienceTosValid(isValid) {
            // If it's validated and we're checking it disable
            if (isValid && this.customAudienceTosDetectionActive) {
                this.customAudienceTosDetectionActive = false;
                clearInterval(this.customAudienceTosDetectionInterval);
            }
        },
        leadsTosDetectionActive(isActive) {
            this.onboardingStatusLoading = isActive;
            this.customAudienceTosDetectionActive = isActive;
        }
    },
    async mounted() {
        this.loadingBmAccess = true;

        // Ensure the dealer is fully loaded since we need immediate
        // access to the annotations created before
        await this.$store.dispatch('waitForDealerLoaded');

        // Recheck the feature status since the page/ad account
        // steps can affect this page
        await this.$store.dispatch('updateDealerFeatureStatus', {
            features: this.outstandingFeatures
        });

        await this.checkBmAccess();
        this.loadingBmAccess = false;
    },
    destroyed() {
        clearInterval(this.customAudienceTosDetectionInterval);
        clearInterval(this.leadsTosDetectionInterval);
        clearInterval(this.paymentMethodDetectionInterval);
    },
    methods: {
        async onRecheckOnboardingStatus() {
            this.onboardingStatusLoading = true;
            await this.$store.dispatch('updateDealerFeatureStatus', {
                features: this.outstandingFeatures
            });
            this.onboardingStatusLoading = false;
        },
        async checkBmAccess() {
            try {
                const response = await this.$http.post(`/agencies/${this.currentDealer.agency.id}/check-bm-access`);
                this.bmAccessStatus = response.data.status;
            } catch (error) {
                this.handleError('Error checking business manager access', error);
            }
        },
        async grantAdAccountAccess() {
            try {
                this.loadingAdAccountAccess = true;
                const response = await this.$http.post(`/facebook_ad_account_annotations/${this.dealerFacebookAdAccountAnnotation.id}/grant-user-access`);

                if (['user_already_has_access', 'user_added_to_ad_account'].includes(response.data.status)) {
                    this.userInAdAccount = true;
                }
            } catch (error) {
                this.handleError('Error granting ad account access', error);
            } finally {
                this.loadingAdAccountAccess = false;
            }
        },
        async grantPageAccess() {
            // Only run page access if we know we have access
            if (!this.pageAccessValid) {
                return;
            }

            try {
                this.loadingPageAccess = true;
                const response = await this.$http.post(`/facebook_page_annotations/${this.dealerFacebookPageAnnotation.id}/grant-user-access`);

                if (['user_already_has_access', 'user_added_to_page'].includes(response.data.status)) {
                    this.userInPage = true;
                }

            } catch (error) {
                this.handleError('Error granting page access', error);
            } finally {
                this.loadingPageAccess = false;
            }
        },
        initiateCustomAudienceTosDetection() {
            // Reset initial data
            this.customAudienceTosDetectionTimeout = false;
            this.customAudienceTosDetectionTries = 0;
            this.customAudienceTosDetectionActive = true;

            // Start the interval
            this.customAudienceTosDetectionInterval = setInterval(() => {
                this.customAudienceTosDetectionTries++;
                // When the tries reach their threshold stop
                if (this.customAudienceTosDetectionTries > 5) {
                    this.customAudienceTosDetectionTimeout = true;
                    this.customAudienceTosDetectionActive = false;
                    return clearInterval(this.customAudienceTosDetectionInterval);
                }
                this.$store.dispatch('updateDealerFeatureStatus', {
                    features: ['facebook_custom_audience_tos']
                });
            }, 2000);
        },
        initiateLeadsTosDetection() {
            // Reset initial data
            this.leadsTosDetectionTimeout = false;
            this.leadsTosDetectionTries = 0;
            this.leadsTosDetectionActive = true;

            // Start the interval
            this.leadsTosDetectionInterval = setInterval(() => {
                this.leadsTosDetectionTries++;
                // When the tries reach their threshold stop
                if (this.leadsTosDetectionTries > 5) {
                    this.leadsTosDetectionTimeout = true;
                    this.leadsTosDetectionActive = false;
                    return clearInterval(this.leadsTosDetectionInterval);
                }
                this.$store.dispatch('updateDealerFeatureStatus', {
                    features: ['facebook_lead_tos']
                });
            }, 2000);
        },
        initiatePaymentMethodDetection() {
            // Reset initial data
            this.paymentMethodDetectionTimeout = false;
            this.paymentMethodDetectionTries = 0;
            this.paymentMethodDetectionActive = true;

            // Start the interval
            this.paymentMethodDetectionInterval = setInterval(() => {
                this.paymentMethodDetectionTries++;
                // When the tries reach their threshold stop
                if (this.paymentMethodDetectionTries > 10) {
                    this.paymentMethodDetectionTimeout = true;
                    this.paymentMethodDetectionActive = false;
                    return clearInterval(this.paymentMethodDetectionInterval);
                }
                this.$store.dispatch('updateDealerFeatureStatus', {
                    features: ['facebook_ad_account']
                });
            }, 2000);
        },
        onCopy() {
            this.$flash('URL successfully copied to your clipboard', 'green');
        },
        addPaymentMethod() {

            // Redirect the user to the Facebook payment settings page
            window.open(this.paymentSettingsUrl);
            return;

            // // If the app is not running on hosts supported by the BB Facebook
            // // app the Facbeook dialog will not work so just open the link
            // const supportedHosts = [
            //     'app.buyerbridge.io',
            //     'app.dealersunited.com'
            // ];

            // // @todo change this when we allow resellers to have their own app IDs
            // if (!supportedHosts.includes(document.location.hostname)) {
            //     this.initiatePaymentMethodDetection();
            //     window.open(this.paymentSettingsUrl);
            //     return;
            // }

            // this.addAccountDialogError = false;
            // // eslint-disable-next-line
            // FB.ui({
            //     account_id: this.dealerFacebookAdAccount,
            //     display: 'popup',
            //     method: 'ads_payment',
            // },
            // (response) => {
            //     if (response && !response.error_message) {
            //         this.$store.dispatch('updateDealerFeatureStatus', {
            //             features: ['facebook_ad_account']
            //         });
            //     } else {
            //         this.addAccountDialogError = true;
            //     }
            // });
        },
        openCustomAudienceTos() {
            this.initiateCustomAudienceTosDetection();
            window.open(this.customAudienceTosUrl);
        },
        openLeadsTos() {
            this.initiateLeadsTosDetection();
            window.open(this.leadsTosUrl);
        },
        handleError(label, error) {
            // If the API has returned messages display them to the user
            const messages = get(error, 'response.data.error.messages', null) || [];
            if (messages.length) {
                this.errors = [
                    ...this.errors,
                    ...messages
                        .filter(message => !message.includes('on line'))
                        .map(message => `${label}: ${message}`)
                ];
            }
            // Otherwise just append in the label
            else {
                this.errors.push(label);
            }

            console.error(label, error);
        }
    }
};
</script>


<style lang="scss" scoped>

.panel {
    margin: 60px 40px;
    height: 100%;
    /* @media (min-width: $bp-md) {
        margin: 60px 70px;
    } */
    @media (min-width: $bp-lg) {
        margin: 60px 100px;
    }
}

</style>
